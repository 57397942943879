import React from 'react';
import Draggable from 'react-draggable';
import {
  Link
} from "react-router-dom";

class Home extends React.Component{

  displayTitle = () =>{
    document.getElementById('artist').style.display="block";
  }

  toMain = () =>{
    window.scrollTo(0, 680);
  }

  displayPos = (e) =>{
    console.log(e.clientX, e.clientY);
  }


  load = () => {
    // setTimeout(function(){document.getElementById('drag').style.opacity="0"}, 5000);
  }

  changePos = () =>{
    window.scrollTo(0, 0);
    // this.props.changePos();
  }

render(){
  const dragHandlers = {onStart: this.onStart, onStop: this.onStop};

  return(
    <div className="Home" onLoad={this.load}>
      <header onClick={this.displayPos} className="App-header">
        <div className="canvas">
          <img src="1.png" className="writing"
          alt="Hi there, you’ve reached Cindy Hu’s website.
            I am Cindy and 胡怡凡 in my mother tongue.
            Thank you for taking the time to get to know me a little.

            I used to think of myself as a designer. I loved creating beautiful digital and tangible interfaces. I still do, to an extent, but I increasingly feel disconnected to the visual languages that took over the digital medium. I grew a little tired of the “perfectly curated aesthetics”. I miss writings on paper and the room to make unintentional marks and mistakes. You are now looking at this oddly formatted personal website because I want to nudge myself in the direction to bring my imperfect process to you. Let’s both accept that this website will forever be a work-in-progress.

            I am now a researcher and artist interested in the multi-faceted nature of our relationship with technological and social progress. My current research interest is understanding nostalgia in a time of accelerated rhythms of life.
            Most recently I worked as a fellow at the Interactive Media Arts program at NYU Shanghai.

            In 2020, I participated in a group exhibition that I co-produced with fellow young artists from the Interactive Media Arts program.
            I showcased the current state of my ongoing project xin cun longing. The project is a combination of photography, research and installation on investigating the nostalgia and the poetry of the physical infrastructure and social structure of 新村 New Villages in China.

            Reading is an important part of my creative process. The books that I am currently reading (and scattered around my apartment) are:
            The Questions Concerning Technology in China by Yuk Hui
            Shanzhai: Doncstruction in China by Byung-Chul Han

            My older projects might give you a glimpse into how I get to the current stage of my practice: " />
        </div>


          <div className="hover" id="down" onClick={this.toMain}>
          </div>
          <a href="https://ima.shanghai.nyu.edu" target="_blank" rel="noopener noreferrer"> <div className="hover link" id="imalink">
          </div></a>
          <div id="xincunphoto">
            <Link onClick={this.changePos} to="/xincun">
              <img src="xincun.jpg" className="projectphoto" alt="Installation view of xin cun longing"/>
            </Link>
          </div>
          <div className="projects" id="pro">

            <Link onClick={this.changePos} to="/hololens">
              <img src="hololens.jpg" className="projectphoto" alt="HoloLens User Experience Design Project"/>
              <img src="Hololens.png" className="projecttitle" id="holotitle" alt="An UX research project on designing an artist portfolio with Microsoft HoloLens" />
            </Link>
            <img className="projectmore" src="Hololens2.png" alt="An UX research project on designing an artist portfolio with Microsoft HoloLens" />
            <Link onClick={this.changePos} to="/pcomp">
              <img src="pcomp.jpg" className="projectphoto" alt="Physical Computing Project"/>
              <img src="Pcomp.png" className="projecttitle" alt="I’ve worked with physical computing quite a bit. Here is a compilation of what I’ve done. " />
            </Link>
            <a href="https://www.instagram.com/cindyyfhu/" target="_blank" rel="noopener noreferrer">
              <img src="collage.jpg" className="projectphoto" alt="Photography Collage Project"/>
              <img src="Collage.png" className="projecttitle" alt="I use digital and analog photography as my creative medium a lot. I love making collages to experiment with how I tell stories through them." />
            </a>

            <a href="https://www.linkedin.com/in/yifancindyhu/" target="_blank" rel="noopener noreferrer"> <div className="hover link" id="linkedin"> </div> </a>
            <a href="mailto:hello@cindy-hu.com" target="_blank" rel="noopener noreferrer"> <div className="hover link" id="email">
            </div></a>

            <a href="https://www.instagram.com/cindyyfhu/" target="_blank" rel="noopener noreferrer"> <div className="hover link" id="insta">
            </div></a>
            <img className="projectmore" id="ending" src="Ending.png" alt="If you want to know me more formally, you can find my professional experiences here on LinkedIn or email me for my CV. Or for a more casual option, find me on Instagram and maybe we can grab a coffee together. Thank you for bearing through this overly long self introduction letter. I would love to get to know you too. Best, Cindy" />
          </div>

        </header>
        <Draggable {...dragHandlers}>
            <div className="hover" id="welcome">
              <img className="drag-img" draggable={false} src="draggable/welcome.png" alt="welcome"/>
            </div>
        </Draggable>
        <Draggable {...dragHandlers}>
            <div className="hover" id="welcome2">
              <img className="drag-img" draggable={false} src="draggable/virtual.png" alt="welcome"/>
            </div>
        </Draggable>

      </div>
  );
  }
}

export default Home;
