import React from 'react';
import Draggable from 'react-draggable';
import { Link } from "react-router-dom";
import './projects.css';

class Project extends React.Component{


  render(){
    const dragHandlers = {onStart: this.onStart, onStop: this.onStop};

    return(
      <div className="project" >
      <header className="App-header">
        <div className="canvas" id="bg">
        <img src="empty.png" className="writing" alt="background with lines" />
        </div>
        <Link onClick={this.props.toProject} to="/"><img src="back.png" id="back" alt="back button" /></Link>
        <div className="text">
          {this.props.content}
        </div>
        </header>

        <Draggable {...dragHandlers}>
            <div className="hover dragtitle">
              <img className="drag-img" alt="draggable title" draggable={false} src={this.props.project.dragImg} />
            </div>
        </Draggable>
      </div>
    );
  }
}

export default Project;
