import React from 'react';
import Project from './project.js';
import Home from './Home.js';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './App.css';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      projects: [
        {title: 'xīn cūn longing',
        dragImg: ['draggable/xincun.png', ], id: 0 },
        {title: 'A User Exerperience Design Research Project on Creating an Artist Portfolio with Microsoft HoloLens',
        dragImg: ['hololens/glass.png', ], id: 1 },
        {title: 'Compilation of my Physical Computing Projects',
        dragImg: ['./draggable/arduino.png'], id: 2}
      ],
      landscape: [
       {original: 'xincun/gallery/1.JPG'},
       {original: 'xincun/gallery/3.JPG'},
       {original: 'xincun/gallery/10.jpg'},
     ],
     custom: [
      {original: 'xincun/gallery/5.JPG'},
      {original: 'xincun/gallery/11.jpg'},
      {original: 'xincun/gallery/12.jpg'},
      {original: 'xincun/gallery/14.jpg'},
    ],
     landscape645: [
      {original: 'xincun/gallery/17.jpg'},
      {original: 'xincun/gallery/6.jpg'},
      {original: 'xincun/gallery/7.jpg'},
      {original: 'xincun/gallery/8.jpg'},
      {original: 'xincun/gallery/9.jpg'}
    ],
      portrait: [
      {original: 'xincun/gallery/2.JPG'},
      {original: 'xincun/gallery/21.jpg'},
      {original: 'xincun/gallery/19.jpg'},
      {original: 'xincun/gallery/18.jpg'},
      {original: 'xincun/gallery/15.jpg'},
      {original: 'xincun/gallery/16.jpg'},
      {original: 'xincun/gallery/23.jpg'},
      {original: 'xincun/gallery/22.jpg'},
      {original: 'xincun/gallery/4.JPG'}
    ],
        clickedPos: 0
    }
  }

  // changePos = (e) =>{
  //   window.scrollTo(0, 0);
  //   if (e){
  //     console.log(e.clientY);
  //     this.setState({clickedPos: e.clientY});
  //   }
  // }

  // toProject = () =>{
  //   window.scrollTo(0, this.state.clickedPos);
  // }
  renderxincun = () =>{
    return(
      <div className="xincuntext">
        <h1 style={{'marginBottom':1+'em', 'fontSize':3+'em'}} >xīn cūn longing</h1>
        <p>“Why is it that the age of globalization is accompanied by a no less global epidemic of nostalgia? What happens to Old World memories in a New World order?”</p>
        <p><i>- The Future of Nostalgia, Svetlana Boym</i></p>
        <ImageGallery showBullets={true} showNav={false} showPlayButton={false} showThumbnails={false} items={this.state.landscape} />
        <p>“xīn cūn longing” ponders upon my personal nostalgia to my childhood home in a common Chinese residential complex New Village. As communal living spaces, xin cun, translated as New Villages, embody the spatial practices of Chinese socialist urban reform and the collective memory of their community. Built decades ago for the working class, these new villages have been there through urban evolution and development all the way to their re-assignment and utilization in today’s commercial housing market. The passing of time has left generous marks on the plain and ordinary facades of these Soviet style buildings as well as dramatic changes in the demographic of their residents. While some are lucky enough to stand through time, others like my childhood home had to be demolished to make way for newer developments. The project is a visual poem of my longing. </p>

        <ImageGallery showBullets={true} showNav={false} showPlayButton={false} showThumbnails={false} items={this.state.custom}/>


        <p style={{'marginTop':3+'em'}}>Installation View, Futurelab Art and Education Expo, Westbund Shanghai, 2020</p>
        <img className="prophoto" src="xincun/2.JPG" style={{'marginBottom':3+'em'}} alt="Installation view of xīn cūn longing" />
        <img className="prophoto" src="xincun/3.JPG" style={{'marginBottom':3+'em'}} alt="Installation view of xīn cūn longing" />

        <img className="prophoto" src="xincun/4.png" alt="Installation view of xīn cūn longing" />
        <img className="prophoto" src="xincun/5.png" alt="Installation view of xīn cūn longing" />
        <img className="prophoto" src="xincun/7.JPG" alt="Installation view of xīn cūn longing" />
        <img className="prophoto" src="xincun/8.png" alt="Installation view of xīn cūn longing" />
        <img className="prophoto" src="xincun/9.JPG" alt="Installation view of xīn cūn longing" />
        <img className="prophoto" src="xincun/10.png" alt="Installation view of xīn cūn longing" />

        <p>xīn cūn longing originated from a group of photographs that I took in 2017 before my childhood home in Fuzhou was about to be demolished. As part of the city’s urban renovation plan, the complex, one of the many xin cuns across the country, needed to make way for newer developments. That group of photos spoke to me deeply and I kept coming back to the photo series in the years to come but struggled to find a home for my nostalgia. </p>

        <p>In 2019, I started to research and dig deeper into the xin cun project. I had three lines of inquires to drive my research: social-political history, architecture and termiology. I was pleasantly surprised by the information I managed to gather. These xin cuns were not only the spatial practices of Chinese socialist reform, they were also the early experiments of the urban grid management system. The architecture style is modeled after Soviet social housing Khrushchyovkas that can be commonly seen all across former Soviet Union countries. And the term “xin cun” can be traced all the way back to a Utopian Socialism experiment proposed by a Japanese novelist Saneatsu Mushanokōji. The experimental community he built influenced important Chinese intellectuals including Mao in his early years. </p>

        <p>Yet with all the new information at hand, I still felt at lost when finding the proper expression for the project. So I went back to the feeling that drove me to this project in the first place. I started reading about nostalgia. Scholar Svetlana Boym’s book <i>The Future of Nostalgia</i> has given me the incredible vocabulary to put the lingering emotions in my head into words. At the same time, there were some life changes. I moved into my first apartment in a xin cun complex in Shanghai and continued the photo project in my new complex. I was offered an opportunity to showcase the work in an exhibition setting. I have met a new friend Irie who has a background in architecture and she instantly connected with me on our collective memories of growing up in xin cun complexes. It was a natural choice to collaborate with her and we started thinking about the project in more spatial and material terms. </p>

        <ImageGallery showBullets={true} showNav={false} showPlayButton={false} showThumbnails={false} items={this.state.landscape645}/>
        <p>I already knew by then that I would like to refer to the communal laundry hangings in the way I display my photos. I often find those laundry areas full of tension, an in-between space of public and private, personal and communal. The residents put their laundry out for drying, but at the same time offering a segment of their personal life to the semi-public audience. In the same way I would put my personal nostalgia to the xin cun living out in the public, for the audience to walk through, touch or photograph. Irie suggested the center piece installation of a study and the fish tank, adding another symbolic layer to the piece. The study table was an found object in her xin cun complex trash and is the classical style of a 80s study table. We custom-made a stainless steel structure to lift the table up to a proper height. On the table, we put objects for the audience to study: the book <i>The Future of Nostalgia</i> and a fishtank with materials collected from my xin cun complex. Construction sand, concrete and soil displayed by layers and on top, plants growing beyond the ability of our control: a metaphor of what makes us both so obsessed with xin cuns, their layers of history and stories as well as their seemingly ordinary surfaces that are full of life.</p>
        <ImageGallery showBullets={true} showNav={false} showPlayButton={false} showThumbnails={false} items={this.state.portrait} />
        <img className="prophoto" src="xincun/11.jpg" alt="Scan of a quote from The Future of Nostalgia" />
      </div>
    );
  }

  renderHolo = () =>{
    return(
        <div>
        <h1>A User Exerperience Design Research Project on Creating an Artist Portfolio with Microsoft HoloLens</h1>
        <h2>Microsoft HoloLens</h2>
         <p>Microsoft HoloLens is the first self-contained, holographic computer, enabling users to engage with digital content and interact with holograms in the world around them. Specialized components—like multiple sensors, advanced optics, and a custom holographic processing unit—enable users to go beyond the screen. HoloLens has the potential to transform the ways we communicate, create, collaborate, and explore and creates a new way to see your world.</p>
          <h2>Design Challenge</h2>
          <p>Our challenge was to design a proof of concept for a holographic portfolio that covers the end-to-end experience of an artist's portfolio to be viewed on the HoloLens by curators. Through intensive research, comparative analysis and several rounds of prototyping and testing, we imagine a holographic portfolio that enables curators to experience and sense art works in a new way.</p>
          <p><strong>Time:</strong> 8 Weeks</p>
          <p><strong>Team: </strong> The team for this project included six other NYU User Experience Design students. My role in the team was leading the design team for visual presentation and documentation of all our findings and design as well as participating in the process of research, brainstorm, ideation, prototype and testing.</p>
          <img className="prophoto" src="hololens/1.jpg" alt="Brainstorming session"/>
    			<h2>Our Design Process</h2>
    			<img className="prophoto" src="hololens/iteration-diagram.jpg" alt="design process graph"/>
    			<h2>Research</h2>
          In order to understand how to approach this design challenge, we decided to get inside the minds of our potential users. We spoke to the following art professionals to gain a better understanding of what curators look for when they view an artist’s work, how artists showcase their work to the public, and which areas of the curatorial experience could be improved:
    			<h4>Sarah Humphreville, Senior Curatorial Assistant, Whitney Museum of American Art</h4>
    			Sarah spoke to us about how she curates for exhibitions and for the Whitney’s permanent collection.
    			<h4>Emily King, Archivist, Roy Lichtenstein Foundation; formerly Registrar at Fales Library and Special Collections at NYU</h4>
    			Emily spoke to us about what curators look for when they visit an art foundation or art archive. She gave us a tour of Roy Lichtenstein’s former studio, and also spoke to us about an art organization’s responsibility to present art to the public on behalf an artist.
    			<h4>Richard Sigmund, Artist & Art Handler</h4>
    			Richard spoke to us about how he presents his art to the public. He also gave us a tour of his studio and spoke to us about his art making process.

    			<p>The following affinity diagram showcases our key findings.</p>

    			<img className="prophoto" src="hololens/2.jpg" alt="affinity diagram showcases our key findings"/>

    			<p>More details about our research findings on current virtual reality and mixed reality user experience design trend can be found in our booklet at the end of the page. </p>

    			<h2>Identifying a Concept</h2>
    			<p>“Immersive Web” is the name we gave to the final concept that emerged from our brainstorming. The Immersive Web shows a web diagram of artists, with visualizations of their work connected to their name in a web format. We imagine our product to be a scalable platform for artists to submit their artworks for the curators to view. </p>
    			<img className="prophoto" src="hololens/4.jpg" alt="immersive web concept visualization"/>

    			<h2>Design Principles</h2>
    			<h4>Minimalism</h4>
    			Our concept is aesthetically minimal. We want the focus of the experience to be the artwork and the artwork’s ability to interact with physical space. We kept the number of visual distractions to a minimum. For example, instead of having many icons on display at all times, we incorporated most of our icons into a hamburger menu. We also kept text display to a minimum, designing workarounds for user access to important text-based information
    			<h4>Intuition</h4>
    			We want the user to rely on their intuition during our mixed reality portfolio experience. For this reason, we kept audio instructions to a minimum and avoided repetitive information and commands.
          <h4>Consistency</h4>
    			The features in our portfolio are standardized, meaning that each feature can be applied to every artwork. This creates a streamlined user experience, and also allows for our idea to be applied to broader contexts.

          <h2>Prototyping</h2>
          <h3>Round One</h3>
    			<p>Our prototyping phase started with "bodystorming". Using Post-It notes, an old sandwich box and classroom whiteboards, we made a video acting out the most basic interactions of our Immersive Web experience. </p>
    			<img className="prophoto" src="hololens/5.jpg" alt="prototyping round one - bodystorming"/>
    			<h4>Testing results:</h4>
    			<p>We asked our testing participants to narrate their understanding of the experience. A quick round of user feedback alerted us to the fact that users may want to search or filter artists in the initial large display of many portfolios. While this prototype was good for getting a sense of how our concept would feel, we quickly realized it wasn’t detailed enough. </p>

          <h3>Round Two</h3>
          <p>
    			After the designers hashed out the details on UX writing, voice commands and displaying information, we incorporated our decisions into a new interactive prototype. We used Marvel’s POP application. </p>
    			<img className="prophoto" src="hololens/6.jpg" alt="prototyping round two - interactive prototype with Marvel"/>
    			<h4>Testing results:</h4>
    			<p>We tested our Marvel prototype out on a group of our peers. We received confirmation that we created a straightforward experience. We asked our users to fully click around the application and narrate their decision making process and their understanding of the experience. This round of user testing helped us identify gaps in our user flow. We realized that we needed to incorporate more clear exit instructions for our immersive experiences. We also realized that we could more clearly explicate features specific to the HoloLens, like holographic placement and the Tag Along feature. One user said she kept expecting to “see more stuff”--by which she meant visual that actions had been performed. Lastly, we realized we should have icons accompanying any text. Feedback from our peers gave us more than enough material to take back to the drawing board.</p>

    			<a href="https://marvelapp.com/433bg1g" target="_blank" rel="noopener noreferrer"><i>View Interactive Prototype</i></a>

    			<h3>Round Three</h3>
    			<p>We used the unity gaming engine to develop our prototype. Our development team managed to develop a rough interactive prototype for users to test on. </p>
    			<img className="prophoto" src="hololens/development.jpg" alt="Unity development process"/>
    			<img className="prophoto" src="hololens/7.jpg" alt="prototyping round three - trying out the prototype on hololens"/>
    			<h4>Testing results:</h4>
    			<p>Testing our prototype on the HoloLens affirmed that the artist web actually feels immersive. We realized we didn’t need lines indicating that the holographic structure is a web--users said it was clunky and unnecessary. In retrospect, the only reason we kept the lines was in reference to our original 2D prototype. We again heard that we need to incorporate audio or visual cues indicating that an action has been selected. We also were encouraged to make our portfolio more 3D and to play with polygonic shape and structure more.
    			</p>
          <p>
    			We also got feedback that we should not have any objects that are above or below the field of view. Having objects that are horizontally not in the field of view was fine--users knew to look to their left and right to uncover objects. However, users did not know to look up or down to view additional objects.
    			</p>
          <p>
    			Lastly, we heard that users would like to be able to scroll through the available artworks, instead of having to go back to the artist web every time they want to move on to the next piece. We were unable to incorporate this on our HoloLens prototype, but we did include it on our second Marvel prototype.
    			</p>

          <h2>Project Outcomes</h2>
    			<h4>Table of Elements</h4>
    			<p>Below is our table of elements. It is inspired by 8ninth’s mixed reality table of elements. The table of elements collects all methods and technique available in mixed reality design. We have customized our table of elements to highlight the methods implemented in our holographic portfolio design. We included a category called “Distraction Isolation.” This category indicates the methods we use to keep our experience aesthetically minimal.</p>
    			<img className="prophoto" src="hololens/table-of-elements.jpg" alt="Table of Elements"/>
    			<h4>User Flows</h4>
    			<p>Below is a sample user flow for viewing sculpture in our product. User flows for other artworks can be found in the booklet at the end of the page.</p>
    			<img className="prophoto" src="hololens/user-flow-sculpture.jpg" alt="User Flow for viewing sculpture in our product"/>
    			<h4>Digital Visualizations</h4>
    			<p>Our digital visualizations show each medium reflected in our portfolio: painting, sculpture, interactive installation, and video. We selected real artwork that we feel highlights the possibilities of viewing art in a mixed reality format on the HoloLens. Further details about the artworks can be found in the booklet.</p>
    			<img className="prophoto" src="hololens/d1.png" alt="digital visualization for our product"/>
    			<img className="prophoto" src="hololens/d2.png" alt="digital visualization for our product"/>
    			<img className="prophoto" src="hololens/d3.png" alt="digital visualization for our product"/>
    			<img className="prophoto" src="hololens/d4.png" alt="digital visualization for our product"/>

    			<p>If you are interested, we have a 70 page documentation booklet detailing the process, learning and outcomes of this project. You can read more about it <a href="https://issuu.com/cindyyhu/docs/hologlass_final" target="_blank" rel="noopener noreferrer"><i>here</i></a>.</p>
        </div>
    );
  }

  renderPcomp = () =>{
    return(
      <div>
      <h1>Compilation of my Physical Computing Projects</h1>
      <p>
      I have a lot of experiences working with Arduinos, sensors and breadboards to build prototypes for interactive projects. All of the projects listed are built using Arduino and completed under tight deadlines. They really helped solidify my rapid prototyping skills. </p>
      <h2>Interactive Button for an Ice Cream Museum</h2>
      <img className="prophoto" src="pcomp/button1.jpg" alt="Interactive Button for an Ice Cream Museum"/>
      <p>The project is an interactive button for an ice cream museum in Zhejiang, China. I built the prototype from scratch and coded the interaction through Arduino. The button is composed of an Arduino Pro board, a touch sensor, a DFRduino MP3 Player and a speaker. I designed and assembled a plastic structure to hold the components and stablize the system. The exhibition design team produced the housing of the buttons. The installation was completed in September, 2017. </p>
      <div className='embed-container'><iframe src='https://player.vimeo.com/video/232977795' frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>

      <h2>Punch the Music</h2>
      <img className="prophoto" src="pcomp/music.jpg" alt="Punch the Music"/>
      <p>The project is a music controller inspired by the old-school dancing mat concept. I used velostat and conductive fabric to make my own soft buttons. I connected these buttons with an Arduino Micro board and used the P5 library to control the music.</p>
      <div className='embed-container'><iframe src='https://player.vimeo.com/video/207409920' frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>

      <h2>Recycled Game Controller</h2>
      <img className="prophoto" src="pcomp/game.jpg" alt="Recycled Game Controller"/>
      <p>The project is a game controller for the classic online game Lunar Lander. It is composed of two buttons, a joystick, a potentiometer, an Arduino micro and materials found in a recycle bin.
      </p>
      <div className='embed-container'><iframe src='https://player.vimeo.com/video/202117935' frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>
      </div>
    );
  }


  render(){

    return (
      <Router>
      <div className="App">
          <Switch>
            <Route path="/xincun"><Project project={this.state.projects[0]} content={this.renderxincun()}/></Route>
            <Route path="/hololens"><Project project={this.state.projects[1]} content={this.renderHolo()}/></Route>
            <Route path="/pcomp"><Project project={this.state.projects[2]} content={this.renderPcomp()}/></Route>

            <Route path="/"><Home /></Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
